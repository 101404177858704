var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board__list"
  }, [_c('h2', {
    staticClass: "tit tit--lg line-height-1 text-center mb-20 mb-lg-40"
  }, [_vm._v(" 이벤트 ")]), _c('div', [_c('table', {
    staticClass: "board-list board-list--main"
  }, [_vm._m(0), _c('tbody', [_vm._l(_vm.fixed, function (board) {
    return _c('tr', {
      key: board._id,
      on: {
        "click": function ($event) {
          return _vm.move(board._id);
        }
      }
    }, [_vm._m(1, true), _c('td', {
      staticClass: "board-list__tit",
      attrs: {
        "align": "center"
      }
    }, [_c('div', {
      staticClass: "board-list__tit"
    }, [_c('p', {
      staticClass: "grey-6--text page-text ellip"
    }, [_vm._v(" " + _vm._s(board === null || board === void 0 ? void 0 : board.subject) + " ")])])]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_c('span', {
      staticClass: "font-size-14"
    }, [_vm._v(_vm._s(_vm.$dayjs(board === null || board === void 0 ? void 0 : board.createdAt).format("YYYY.MM.DD")))])])]);
  }), _vm._l(_vm.notifications, function (notification) {
    return _c('tr', {
      key: notification._id,
      on: {
        "click": function ($event) {
          return _vm.move(notification._id);
        }
      }
    }, [_vm._m(2, true), _c('td', {
      staticClass: "board-list__tit",
      attrs: {
        "align": "center"
      }
    }, [_c('div', {
      staticClass: "board-list__tit"
    }, [_c('p', {
      staticClass: "grey-6--text page-text ellip"
    }, [_vm._v(" " + _vm._s(notification === null || notification === void 0 ? void 0 : notification.subject) + " ")])])]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_c('span', {
      staticClass: "font-size-14"
    }, [_vm._v(_vm._s(_vm.$dayjs(notification === null || notification === void 0 ? void 0 : notification.createdAt).format("YYYY.MM.DD")))])])]);
  })], 2)])]), _c('div', {
    staticClass: "v-pagination-wrap"
  }, [_c('v-pagination', {
    staticClass: "mt-4 mb-12",
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": _vm.search
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', {
    staticClass: "d-none d-lg-table-header"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "70px"
    }
  }, [_vm._v("NO.")]), _c('th', {
    staticStyle: {
      "width": "100%"
    }
  }, [_vm._v("제목")]), _c('th', {
    staticStyle: {
      "width": "110px"
    }
  }, [_vm._v("날짜")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticClass: "board-list__txt board-list__txt--first",
    attrs: {
      "align": "center"
    }
  }, [_c('i', {
    staticClass: "icon icon-notice"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticClass: "board-list__txt board-list__txt--first",
    attrs: {
      "align": "center"
    }
  }, [_c('span', {
    staticClass: "font-size-14"
  }, [_vm._v("1")])]);

}]

export { render, staticRenderFns }